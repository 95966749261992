import { createSlice } from "@reduxjs/toolkit";
import {
  createOrUpdateUserCompanyNote,
  showUserCompanyNote,
} from "./userCompanyNoteActions";
import { RootState } from "../../app/store";
import {
  UserCompanyNoteResponse,
  UserCompanyNoteShowResponse,
} from "./userCompanyNoteApi";
import filterErrorMessage from "../../utils/filterErrorMessage";

export interface initialState {
  userCompanyNote: UserCompanyNoteResponse | UserCompanyNoteShowResponse | null;
  isSaving: boolean;
  isFinding: boolean;
  isSaveSuccess: boolean;
  isFindingSuccess: boolean;
  isError: boolean;
  errorMessage: string | null;
}

export const userCompanyNoteSlice = createSlice({
  name: "userCompanyNote",
  initialState: {
    userCompanyNote: null,
    isSaving: false,
    isFinding: false,
    isSaveSuccess: false,
    isFindingSuccess: false,
    isError: false,
    errorMessage: null,
  } as initialState,

  reducers: {
    clearState: (state) => {
      state.isSaving = false;
      state.isFinding = false;
      state.isSaveSuccess = false;
      state.isFindingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    clearShowState: (state) => {
      state.userCompanyNote = null;
      state.isFindingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createOrUpdateUserCompanyNote.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(
      createOrUpdateUserCompanyNote.fulfilled,
      (state, { payload }) => {
        state.isSaving = false;
        state.isSaveSuccess = true;
        state.userCompanyNote = payload;
      }
    );
    builder.addCase(createOrUpdateUserCompanyNote.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as UserCompanyNoteResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(showUserCompanyNote.pending, (state) => {
      state.isFinding = true;
      state.isFindingSuccess = false;
    });
    builder.addCase(showUserCompanyNote.fulfilled, (state, { payload }) => {
      state.isFinding = false;
      state.isFindingSuccess = true;
      state.userCompanyNote = payload;
    });
    builder.addCase(showUserCompanyNote.rejected, (state, action) => {
      state.isFinding = false;
      state.isError = true;
      const payload = action.payload as UserCompanyNoteResponse;
      state.errorMessage = filterErrorMessage(
        payload?.error || payload?.error_messages?.[0]
      );
    });
  },
});

export const { clearState, clearShowState } = userCompanyNoteSlice.actions;

export const userCompanyNoteSelector = (state: RootState) =>
  state.userCompanyNote;
