import { FormikProps } from "formik";
import { generateDescription, JobParams } from "../../redux/job/jobApi";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import Editor from "../../components/Editor";
import { toast } from "react-toastify";
import { useState } from "react";
import statusCodes from "../../utils/statusCodes";
import { AutoAwesome } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

interface IJobDescription {
  formik: FormikProps<JobParams>;
}

const JobDescription: React.FC<IJobDescription> = ({ formik }) => {
  const [isGeneratingDescription, setIsGeneratingDescription] =
    useState<boolean>(false);

  const generateDescriptionWithAi = async () => {
    if (isGeneratingDescription) {
      return;
    }

    if (!formik.values.name) {
      toast.info("Título da vaga é obrigatório para gerar descrição com IA");
      return;
    }

    setIsGeneratingDescription(true);
    if (formik.values.id) {
      const response = await generateDescription(formik.values.id.toString());
      if (response.status === statusCodes.ok) {
        formik.setFieldValue("description", response.data.description);
      }
    }
    setIsGeneratingDescription(false);
  };

  return (
    <Accordion expanded>
      <AccordionSummary sx={{ cursor: "auto !important" }}>
        <Typography fontWeight={"bold"}>Descrição</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mb: 2, textAlign: "right" }}>
          <LoadingButton
            variant="ai"
            startIcon={<AutoAwesome />}
            onClick={generateDescriptionWithAi}
            loading={isGeneratingDescription}
          >
            Gerar Descrição com IA
          </LoadingButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormGroup>
              <FormControl fullWidth>
                <Editor
                  id="description"
                  disabled={isGeneratingDescription}
                  value={formik.values.description || ""}
                  onEditorChange={(newValue, editor) => {
                    formik.setFieldValue("description", newValue);
                  }}
                  error={
                    !!(formik.touched.description && formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                      ? formik.errors.description
                      : null
                  }
                />
              </FormControl>
            </FormGroup>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default JobDescription;
