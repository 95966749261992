import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Backdrop from "../components/Backdrop";
import { useNavigate } from "react-router-dom";
import urls from "../utils/urls";

function ProcessingPayment() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(urls.website);
    }, 7000);
  }, []);

  return (
    <Box>
      <Backdrop />
    </Box>
  );
}

export default ProcessingPayment;
