import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormikHelpers } from "formik";
import * as userCompanyNoteApi from "./userCompanyNoteApi";
import statusCodes from "../../utils/statusCodes";
import setFormikErrors from "../../utils/setFormikErrors";

export const createOrUpdateUserCompanyNote = createAsyncThunk(
  "userCompanyNotes/createOrUpdate",
  async (
    {
      params,
      formikBag,
    }: {
      params: userCompanyNoteApi.UserCompanyNoteParams;
      formikBag: FormikHelpers<userCompanyNoteApi.UserCompanyNoteParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await userCompanyNoteApi.createOrUpdate(params);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const showUserCompanyNote = createAsyncThunk(
  "userCompanyNotes/show",
  async (
    { candidate_id, company_id }: { candidate_id: number; company_id: number },
    thunkAPI
  ) => {
    try {
      const response = await userCompanyNoteApi.show(candidate_id, company_id);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
