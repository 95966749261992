import React, { useEffect } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Google, LinkedIn } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { googleSignInUrl, linkedinSignInUrl } from "../../utils/oauth";
import { toast } from "react-toastify";
import { saveToken } from "../../utils/localStorage";
import urls from "../../utils/urls";
import LoginCardContainer from "../../components/LoginCardContainer";
import { colors } from "../../assets/themes";

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const access_token = queryParams.get("access_token");
  const refresh_token = queryParams.get("refresh_token");
  const return_url = queryParams.get("return_url");
  const error = queryParams.get("error");

  useEffect(() => {
    handleLogin();
  }, [access_token, refresh_token, return_url]);

  useEffect(() => {
    if (error) {
      toast.error("Não foi possível completar o login, tente novamente!");
    }
  }, [error]);

  const handleLogin = async () => {
    if (access_token && refresh_token) {
      await saveToken({
        access_token,
        refresh_token,
      });

      if (return_url) {
        navigate(return_url);
      } else {
        navigate(urls.adminCandidateMyApplications);
      }
    }
  };

  return (
    <LoginCardContainer>
      <Box sx={{ mx: 6 }}>
        <Typography
          color={colors.grey}
          fontSize={18}
          fontWeight="600"
          sx={{ mb: 1 }}
        >
          Bem vindo a Reazy!
        </Typography>

        <Typography color={colors.grey} fontSize={14} fontWeight="400">
          Por favor, faça o login para se conectar com a melhor plataforma de
          recrutamento
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          my: 4,
        }}
      >
        <Box sx={{ width: "100%", maxWidth: 300 }}>
          <Link to={googleSignInUrl(return_url)}>
            <Button
              variant="outlined"
              size="large"
              startIcon={<Google />}
              sx={{ width: "100%" }}
            >
              Continuar com Google
            </Button>
          </Link>
        </Box>

        <Box sx={{ width: "100%", maxWidth: 300 }}>
          <Link to={linkedinSignInUrl(return_url)}>
            <Button
              variant="outlined"
              size="large"
              startIcon={<LinkedIn />}
              sx={{ width: "100%" }}
            >
              Continuar com LinkedIn
            </Button>
          </Link>
        </Box>
      </Box>

      <Box sx={{ marginTop: 2, textAlign: "center" }}>
        <Typography fontSize={14}>
          <Link
            to={urls.login}
            style={{
              textDecoration: "none",
              color: theme.palette.primary.main,
            }}
          >
            Logar como empresa
          </Link>
        </Typography>
      </Box>
    </LoginCardContainer>
  );
};

export default Login;
