import React, { useEffect } from "react";
import LoginCardContainer from "../components/LoginCardContainer";
import {
  Avatar,
  Box,
  FormControl,
  FormGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { colors } from "../assets/themes";
import { useAppDispatch, useAppSelector } from "../app/store";
import { acceptInvitation, getInvitation } from "../redux/user/userActions";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearShowState,
  clearState,
  userSelector,
} from "../redux/user/userSlice";
import { LoadingButton } from "@mui/lab";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import urls from "../utils/urls";
import { UserAcceptInvitationResponse } from "../redux/user/userApi";
import Yup from "../utils/yup";
import { useFormik } from "formik";
import PasswordInput from "../components/Input/PasswordInput";

const AcceptInvitationNewUserSchema = Yup.object().shape({
  first_name: Yup.string().max(255).required(),
  last_name: Yup.string().max(255).required(),
  password: Yup.string().min(8).max(255).required(),
  password_confirmation: Yup.string()
    .test("passwords-match", "Senhas não conferem", function (value) {
      return this.parent.password === value;
    })
    .required(),
});

const AcceptInvitationNoPasswordSchema = Yup.object().shape({
  password: Yup.string().min(8).required(),
  password_confirmation: Yup.string()
    .test("passwords-match", "Senhas não conferem", function (value) {
      return this.parent.password === value;
    })
    .required(),
});

const AcceptInvitation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { token } = useParams<{ token: string }>();

  const {
    errorMessage,
    invitation,
    isFetchingInvitation,
    isAcceptingInvitation,
  } = useAppSelector(userSelector);

  useEffect(() => {
    if (token) {
      dispatch(getInvitation(token));
    }

    return () => {
      dispatch(clearState());
      dispatch(clearShowState());
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: invitation?.is_new
      ? AcceptInvitationNewUserSchema
      : !invitation?.has_password
      ? AcceptInvitationNoPasswordSchema
      : null,
    onSubmit: (values, formikBag) => {
      if (token) {
        dispatch(acceptInvitation({ token, params: values, formikBag })).then(
          (value) => {
            if (value.meta.requestStatus !== "rejected") {
              toast.success("Convite aceito com sucesso");
              navigate(urls.login);
            } else {
              toast.error(
                (value.payload as UserAcceptInvitationResponse)?.error
              );
            }
          }
        );
      }
    },
    enableReinitialize: true,
  });

  return (
    <LoginCardContainer>
      {isFetchingInvitation ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 2,
          }}
        >
          <Oval
            visible={true}
            height="50"
            width="50"
            color={theme.palette.primary.main}
            secondaryColor={theme.palette.primary.main}
          />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            {invitation?.company.logo && (
              <Box>
                <Avatar
                  src={invitation?.company.logo}
                  alt="logo"
                  sx={{
                    width: 40,
                    height: 40,
                    border: "5px solid #fff",
                    boxShadow: "0px 0px 6px 1px rgba(0,0,0,0.59)",
                    backgroundColor: (theme) => theme.palette.common.white,
                  }}
                />
              </Box>
            )}
            <Typography
              color={colors.grey}
              fontSize={18}
              fontWeight="600"
              sx={{ textAlign: "center" }}
            >
              {!invitation
                ? errorMessage
                : `${invitation?.company.name} enviou um convite para você`}
            </Typography>
          </Box>

          {invitation && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              {invitation.is_new ? (
                <>
                  <FormGroup sx={{ marginTop: 4 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: 2,
                      }}
                    >
                      <FormControl fullWidth>
                        <TextField
                          type="text"
                          label="Nome"
                          onChange={formik.handleChange("first_name")}
                          onBlur={formik.handleBlur("first_name")}
                          value={formik.values.first_name}
                          error={
                            !!(
                              formik.touched.first_name &&
                              formik.errors.first_name
                            )
                          }
                          helperText={
                            formik.touched.first_name &&
                            formik.errors.first_name
                              ? formik.errors.first_name
                              : null
                          }
                        />
                      </FormControl>

                      <FormControl fullWidth>
                        <TextField
                          type="text"
                          label="Sobrenome"
                          onChange={formik.handleChange("last_name")}
                          onBlur={formik.handleBlur("last_name")}
                          value={formik.values.last_name}
                          error={
                            !!(
                              formik.touched.last_name &&
                              formik.errors.last_name
                            )
                          }
                          helperText={
                            formik.touched.last_name && formik.errors.last_name
                              ? formik.errors.last_name
                              : null
                          }
                        />
                      </FormControl>
                    </Box>
                  </FormGroup>

                  <FormGroup sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                      <PasswordInput
                        label="Criar Senha"
                        onChange={formik.handleChange("password")}
                        onBlur={formik.handleBlur("password")}
                        value={formik.values.password}
                        error={
                          !!(formik.touched.password && formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                            ? formik.errors.password
                            : null
                        }
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <PasswordInput
                        label="Confirmar Senha"
                        onChange={formik.handleChange("password_confirmation")}
                        onBlur={formik.handleBlur("password_confirmation")}
                        value={formik.values.password_confirmation}
                        error={
                          !!(
                            formik.touched.password_confirmation &&
                            formik.errors.password_confirmation
                          )
                        }
                        helperText={
                          formik.touched.password_confirmation &&
                          formik.errors.password_confirmation
                            ? formik.errors.password_confirmation
                            : null
                        }
                      />
                    </FormControl>
                  </FormGroup>
                </>
              ) : !invitation.has_password ? (
                <>
                  {" "}
                  <FormGroup sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                      <PasswordInput
                        label="Criar Senha"
                        onChange={formik.handleChange("password")}
                        onBlur={formik.handleBlur("password")}
                        value={formik.values.password}
                        error={
                          !!(formik.touched.password && formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                            ? formik.errors.password
                            : null
                        }
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <PasswordInput
                        label="Confirmar Senha"
                        onChange={formik.handleChange("password_confirmation")}
                        onBlur={formik.handleBlur("password_confirmation")}
                        value={formik.values.password_confirmation}
                        error={
                          !!(
                            formik.touched.password_confirmation &&
                            formik.errors.password_confirmation
                          )
                        }
                        helperText={
                          formik.touched.password_confirmation &&
                          formik.errors.password_confirmation
                            ? formik.errors.password_confirmation
                            : null
                        }
                      />
                    </FormControl>
                  </FormGroup>
                </>
              ) : null}

              <FormGroup sx={{ mt: 4 }}>
                <FormControl fullWidth>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    size="large"
                    loading={isAcceptingInvitation}
                  >
                    Aceitar
                  </LoadingButton>
                </FormControl>
              </FormGroup>
            </form>
          )}
        </>
      )}
    </LoginCardContainer>
  );
};

export default AcceptInvitation;
