import React, { useState } from "react";
import { customerPortalSession } from "../redux/stripe/stripeApi";
import statusCodes from "../utils/statusCodes";
import Backdrop from "../components/Backdrop";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../app/store";
import { userSelector } from "../redux/user/userSlice";
import { plans } from "../utils/constants";

const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const STRIPE_PRINCING_TABLE = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;

const Billings = () => {
  const { currentUser } = useAppSelector(userSelector);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getCustomerPortalSession = async () => {
    setIsFetching(true);
    const response = await customerPortalSession();
    if (response.status === statusCodes.ok) {
      window.location.href = response.data.url;
    } else {
      toast.error("Não foi possível ver as cobranças, tente novamente!");
      setIsFetching(false);
    }
  };

  if (isFetching) {
    return <Backdrop />;
  }

  return (
    <Box>
      {currentUser.company?.plan.key ? (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Plano Atual
          </Typography>
          <Typography>{plans[currentUser.company?.plan.key]}</Typography>
        </Box>
      ) : null}

      {currentUser.company ? (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Planos Reazy
          </Typography>
          <stripe-pricing-table
            pricing-table-id={STRIPE_PRINCING_TABLE}
            publishable-key={STRIPE_PUBLISHABLE_KEY}
            customer-email={currentUser.company.owner?.email}
          ></stripe-pricing-table>
        </Box>
      ) : null}

      <Box>
        <Typography variant="h5">Faturas</Typography>
        <Typography
          sx={{
            mb: 2,
            color: (theme) => theme.palette.grey[500],
            fontSize: 14,
          }}
        >
          Veja suas faturas, faça um upgrade no seu plano
        </Typography>
        <LoadingButton variant="contained" onClick={getCustomerPortalSession}>
          Ver faturas
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default Billings;
