import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { userSlice } from "../redux/user/userSlice";
import { companySlice } from "../redux/company/companySlice";
import { jobSlice } from "../redux/job/jobSlice";
import { departmentSlice } from "../redux/department/departmentSlice";
import { benefitSlice } from "../redux/benefit/benefitSlice";
import { profileSlice } from "../redux/profile/profileSlice";
import { navSlice } from "../redux/nav/navSlice";
import { passwordSlice } from "../redux/password/passwordSlice";
import { candidateSlice } from "../redux/candidate/candidateSlice";
import { userCompanyNoteSlice } from "../redux/userCompanyNote/userCompanyNoteSlice";
import { candidateNoteSlice } from "../redux/candidateNote/candidateNoteSlice";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    company: companySlice.reducer,
    job: jobSlice.reducer,
    department: departmentSlice.reducer,
    benefit: benefitSlice.reducer,
    profile: profileSlice.reducer,
    nav: navSlice.reducer,
    password: passwordSlice.reducer,
    candidate: candidateSlice.reducer,
    candidateNote: candidateNoteSlice.reducer,
    userCompanyNote: userCompanyNoteSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
