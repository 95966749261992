import { AxiosResponse } from "axios";
import { ResponseWithErrors } from "../../interfaces";
import requestHelper from "../../utils/requestHelper";

export interface UserCompanyNoteParams {
  note: string | null;
  candidate_id: number | null;
  company_id: number | null;
}

export interface UserCompanyNoteIndexAttributes {
  id: number;
  note: string | null;
}

export interface UserCompanyNoteShowResponse
  extends UserCompanyNoteIndexAttributes {}

export interface UserCompanyNoteResponse
  extends UserCompanyNoteShowResponse,
    ResponseWithErrors {}

const createOrUpdate = async (
  params: UserCompanyNoteParams
): Promise<AxiosResponse<UserCompanyNoteResponse>> => {
  return await requestHelper
    .post(`user-company-notes/${params.candidate_id}/${params.company_id}`, {
      user_company_note: params,
    })
    .catch((e) => e.response);
};

const show = async (
  candidate_id: number,
  company_id: number
): Promise<AxiosResponse<UserCompanyNoteShowResponse>> => {
  return await requestHelper
    .get(`user-company-notes/${candidate_id}/${company_id}`)
    .catch((e) => e.response);
};

export { createOrUpdate, show };
