import React from "react";
import { FormLabel, Theme, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { Editor as EditorCore, IAllProps } from "@tinymce/tinymce-react";

interface IEditor extends IAllProps {
  label?: string | null;
  required?: boolean;
  error?: boolean;
  helperText?: string | null;
  height?: string | number | undefined;
}

interface IStylesProps {
  error: boolean;
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) =>
  createStyles({
    container: {
      "& .tox-tinymce": {
        borderColor: ({ error }) =>
          error ? `${theme.palette.error.main} !important` : "",
      },
    },
  })
);

const Editor: React.FC<IEditor> = ({
  label,
  required = false,
  error = false,
  helperText,
  height = undefined,
  ...props
}) => {
  const classes = useStyles({ error });
  const theme = useTheme();

  return (
    <div className={classes.container}>
      {label ? (
        <FormLabel
          error={error}
          sx={{ pl: 1, fontSize: 16, color: theme.palette.common.black }}
        >
          {label} {required && "*"}
        </FormLabel>
      ) : null}
      <EditorCore
        apiKey={process.env.REACT_APP_TINY_MCE_KEY}
        init={{
          menubar: false,
          plugins:
            "autolink charmap emoticons image link lists media searchreplace",
          toolbar:
            "undo redo | bold italic underline strikethrough | link image media | align numlist bullist indent outdent | blocks fontsize | emoticons charmap | removeformat",
          language: "pt_BR",
          height: height,
          content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); body { font-family: 'Montserrat', sans-serif; } h1,h2,h3,h4,h5,h6 { font-family: 'Montserrat', sans-serif; }",
          //       file_picker_types: 'image',
          //       file_picker_callback: function(callback, value, meta) {
          //       if (meta.filetype == 'image') {
          //         const input = document.createElement('input');
          //         input.setAttribute('type', 'file');
          //         input.setAttribute('accept', 'image/*');

          //         input.addEventListener('change', (e) => {
          //           const file = e.target.files[0];

          // const reader = new FileReader();
          // reader.addEventListener('load', () => {
          //   /*
          //     Note: Now we need to register the blob in TinyMCEs image blob
          //     registry. In the next release this part hopefully won't be
          //     necessary, as we are looking to handle it internally.
          //   */
          //   const id = 'blobid' + (new Date()).getTime();
          //   const blobCache =  tinymce.activeEditor.editorUpload.blobCache;
          //   const base64 = reader.result.split(',')[1];
          //   const blobInfo = blobCache.create(id, file, base64);
          //   blobCache.add(blobInfo);

          //   /* call the callback and populate the Title field with the file name */
          //   cb(blobInfo.blobUri(), { title: file.name });
          // });
          // reader.readAsDataURL(file);
          //         }
          //       }
          //     }
        }}
        {...props}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </div>
  );
};

export default Editor;
